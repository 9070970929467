import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import { TermFilterParams } from '../../../components/commercetools-ui/term-filter';

type Filter = {
  terms: {
    [key: string]: {
      params: TermFilterParams[];
      selected: Record<string, boolean>;
    };
  };
  termFilteringParams: URLParam[];
  priceFilteringParams: URLParam[];
  sizeFilteringParams: URLParam[];
  sortingParam: URLParam;
  shouldSubmit: boolean;
};
type FilterContextValue = {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
  resetFilter: () => void;
  submitFilter: () => void;
};

const initialState: Filter = {
  terms: {},
  termFilteringParams: [],
  priceFilteringParams: [],
  sizeFilteringParams: [],
  sortingParam: undefined,
  shouldSubmit: false,
};
const FilterContext = createContext<FilterContextValue>(undefined);

const useFilter = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};

const FilterProvider = (props) => {
  const router = useRouter();
  const [filter, setFilter] = useState(initialState);
  const { termFilteringParams, priceFilteringParams, sortingParam, sizeFilteringParams, shouldSubmit } = filter;

  const resetFilter = () => {
    setFilter(initialState);
  };

  const submitFilter = () => {
    const params = [{ key: 'cursor', value: 'offset:0' }];

    if (priceFilteringParams) {
      params.push(...priceFilteringParams);
    }

    if (sortingParam) {
      params.push(sortingParam);
    }

    if (sizeFilteringParams) {
      params.push(...sizeFilteringParams);
    }

    if (termFilteringParams) {
      params.push(...termFilteringParams);
    }

    const currentUrl = updateURLParams(params);

    router.push(currentUrl);
  };

  const value: FilterContextValue = useMemo(
    () => ({
      filter,
      setFilter,
      resetFilter,
      submitFilter,
    }),
    [filter],
  );

  useEffect(() => {
    if (shouldSubmit) {
      setFilter((filter) => ({
        ...filter,
        shouldSubmit: false,
      }));
      submitFilter();
    }
  }, [termFilteringParams]);

  return <FilterContext.Provider value={value} {...props} />;
};

export { FilterProvider, useFilter, initialState as initialFilterState };
